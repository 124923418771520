/* You can add global styles to this file, and also import other style files */
/* Add application styles & imports to this file! */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
@import '~ngx-sharebuttons/themes/circles/circles-dark-theme';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.stories-container .mat-card-actions .sb-group {
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6196078431) !important;
  z-index: 1;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
 }
  .dialog-bg-trans {
    background-color: transparent;
  }

  
.stories-container {
  .social-container {
      .custom-icon {
          min-width: 20px;
          height: 20px;
          margin: 0 8px;
          background-repeat: no-repeat;
          background-size: 20px;
          background-position: left center;
          color: var(--white);
          font-weight: var(--font-weight-normal);
          font-size: 13px;
          vertical-align: top;
          min-height: 20px;
          display: inline-flex;
          align-items: center;
          &.share-icon-white {
              background-image: url('~src/assets/img/share-icon-black.svg');
              margin-top: 5px;
              margin-right: 20px;
          }
      }
  }
}

